.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* :root {
  --allgpt-header-background:#282c34;
  --allgpt-header-text-color: white;
  --allgpt-chat-background-color:white;
  --allgpt-brand-primary-color:#282c34;
  --allgpt-avatar-url: url("./bot.png");
} */

.closeIcon{
  color: var(--allgpt-header-text-color,white);
}

.botMessage {
  background-color: #eee;
  border-radius: 5px; 
  padding: 10px;
  max-width: 80%;
  margin-left: auto;
}

.userMessage {
  background-color: #58b; 
  color: white;
  border-radius: 5px;
  padding: 10px; 
  max-width: 80%;
  margin-right: auto;
}

.botContainer {
  width: 300px;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: fixed;
  right: 40px;
  bottom: 90px;
  height: 515px;
  display: flex;
  flex-direction: column;
  background-color: var(--allgpt-chat-background-color,white);
  transition: width 0.5s,height 0.5s ease;
  z-index: 99992;
}



.botContainerMinimized {
  box-shadow: 0 0px 8px 8px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  position: fixed;
  right: 22px;
  bottom: 90px;
  height: 50px;
  width: 50px;
  overflow: hidden;
  background-color: var(--allgpt-chat-background-color,white);
  transition: width 0.5s, height 0.5s ease; 
  z-index: 9000;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  background-color: var(--allgpt-header-background,#568fd2);
  border-radius: 10px;
  align-items: center;
}

.avtarName {
  color: var(--allgpt-header-text-color, white);
  font-weight: bold;
}

.closeButton{
  border: none;
  padding: 0px;
  background-color: transparent;
  cursor: pointer
}

.actionButton{
  padding: 10px;
  border-radius: 5px;
  background-color: var(--allgpt-chat-background-color,white);
  /* border: none; */
  margin-top: 10px;
  margin-bottom: 10px;
  
  cursor: pointer;
  
}

.botIconButton{
  border: none;
  padding: 0px;
  background-color: transparent;
  cursor: pointer;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}


.messages {
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
  padding: 10px 10px 10px;
  overflow-y: scroll;
  display:flex;
  flex-direction: column;
  flex: 1;
}
.messages >:first-child{
  margin-top: auto;
}

.messages::-webkit-scrollbar {
  width: 12px;
}

.messages::-webkit-scrollbar-track {
  background: #fff; 
}

.messages::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
  border: 3px solid #fff;
}
.logoContainer{
  display: flex;
  justify-content: start;
  align-items: center;
  color: var(--allgpt-header-text-color,white);
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;

  /* margin-bottom: 50px; */
}

.user-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.send-btn {
  margin-left: 10px;
  padding: 10px 20px; 
  border-radius: 5px;
  background-color: var(--allgpt-brand-primary-color, #282c34);
  color: white;
  border: none;
}

.chatbox{
  height: auto;
}
@media (max-width: 768px) {
  .botContainer {
    top:0; 
    right:0; 
    bottom:0; 
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .messages{
    top:0; 
    right:0; 
    bottom:0; 
    left:0;
    height: 100%;
  }
  .chatbox{
    height: auto;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
